import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

import config from './auth_config.json'
import history from './utils/history'
import { Provider } from 'react-redux'
import store from './store'
import './i18n';
import TagManager from 'react-gtm-module'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'


posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
  api_host: 'https://eu.i.posthog.com',
  person_profiles: 'identified_only',
  capture_pageview: false
})


const onRedirectCallback = appState => {
  const url = new URL(window.location.href);
  url.searchParams.delete('code');
  url.searchParams.delete('state');
  const params = url.searchParams.toString();
    history.push(
      appState.targetUrl ? `${appState.targetUrl}?${params}`:  `${window.location.pathname}?${params}`
    )
}

const tagManagerArgs = {
  gtmId: 'G-KLEGB4S0RH'
}

TagManager.initialize(tagManagerArgs)

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PostHogProvider client={posthog}>
      <App
        domain={config.domain}
        client_id={config.clientId}
        audience={config.audience}
        redirect_uri={window.location.href}
        onRedirectCallback={onRedirectCallback}
      />
    </PostHogProvider>
  </Provider>
)
//serviceWorker.unregister();