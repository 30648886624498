import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { SPONSOR_OBJECTS } from "../keys";

const useGetSponsorObjects = () => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [SPONSOR_OBJECTS, tenant.id],
    queryFn: () => ApiService.getSponsorObjects(),
    select: (data) => data.data,
    placeholderData: {data:[]},
		enabled: tenant.type === 'sponsor',
  });
};

export default useGetSponsorObjects;
