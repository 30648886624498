import axios from 'axios'
import { Globals } from '../Globals'

const ApiService = {
  activateUser(activationCode, payload) {
    return axios({
      url: `${Globals.API_URL}/api/activate/${activationCode}`,
      method: "POST",
      data: payload,
    });
  },
  registerUser(name, email) {
    return axios({
      url: `${Globals.API_URL}/api/register`,
      method: "POST",
      data: {
        name: name,
        email: email
      },
    });
  },
  changeCurrency(currency) {
    return axios({
      url: `${Globals.API_URL}/api/currency/${currency}`,
      method: "POST",
    });
  },
  changeLang(lang) {
    return axios({
      url: `${Globals.API_URL}/api/lang/${lang}`,
      method: "GET",
    });
  },
  createUser(tenantID, name, email) {
    return axios({
      url: `${Globals.API_URL}/api/admin/create-user`,
      method: "POST",
      data: {
        tenantID: tenantID,
        name: name,
        email: email
      }
    });
  },
  generateMeltwaterMetrics() {
    return axios({
      url: `${Globals.API_URL}/api/admin/generate-meltwater-metrics`,
      method: "POST",
    });
  },
  getAccessTokens() {
    return axios({
      url: `${Globals.API_URL}/api/admin/access-tokens`,
      method: "GET",
    });
  },
  getActivationUser(activationCode) {
    return axios({
      url: `${Globals.API_URL}/api/activate/${activationCode}`,
      method: "GET",
    });
  },
  getAllTenants() {
    return axios({
      url: `${Globals.API_URL}/api/admin/all-tenants`,
      method: "GET",
    });
  },
  getAllUsers() {
    return axios({
      url: `${Globals.API_URL}/api/admin/all-users`,
      method: "GET",
    });
  },
  getBrandPressCount(since, until) {
    return axios({
      url: `${Globals.API_URL}/api/brand/count?since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getBrandSentiment(since, until) {
    return axios({
      url: `${Globals.API_URL}/api/brand/sentiment?since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getBrandSources(since, until) {
    return axios({
      url: `${Globals.API_URL}/api/brand/sources?since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getCurrencyConvertion() {
    return axios({
      url: `${Globals.API_URL}/api/currency_convertions`,
      method: "GET",
    });
  },

  getDemographics(since, until) {
    return axios({
      url: `${Globals.API_URL}/api/demographics?since=${since}&until=${until}`,
    });
  },
  getPartnerEngagementValue(partnerId = "", selectedTenantId = "", since, until) {
    return axios({
      url: `${Globals.API_URL}/api/partners/engagement/value?partnerId=${partnerId}&selectedTenantId=${selectedTenantId}&since=${since}&until=${until}`,

      method: "GET",
    });
  },
  getProfileData() {
    return axios({
      url: `${Globals.API_URL}/api/person/meta-profile-data`,
      method: "GET",
    });
  },
  setProfileData(formData) {
    return axios({
      url: `${Globals.API_URL}/api/person/meta-profile-data`,
      method: "POST",
      data: formData,
    });
  },
  getDemographicsByLeague(overrideTenantId, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/demographics/league?since=${since}&until=${until}&overrideTenantId=${overrideTenantId}`,
      method: "GET",
    });
  },
  getPartnerReachValue(partnerId = "", selectedTenantId = "", since, until) {
    return axios({
      url: `${Globals.API_URL}/api/partners/reach/value?partnerId=${partnerId}&selectedTenantId=${selectedTenantId}&since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getEngagement(overrideTenantId, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/engagement?since=${since}&until=${until}&overrideTenantId=${overrideTenantId}`,
      method: "GET",
    });
  },
  getEngagementChannels(since, until) {
    return axios({
      url: `${Globals.API_URL}/api/engagement-channels?since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getExportedReport(type, format, since, until, partners, sponsorObjects) {
    const url = new URL(`${Globals.API_URL}/api/reports/export`);
    const params = new URLSearchParams({
      type,
      format,
      since,
      until,
    });

    if (partners) {
      params.append('partners', partners);
    }

    if (sponsorObjects) {
      params.append('sponsorObjects', sponsorObjects);
    }

    url.search = params.toString();
    const finalUrl = url.toString();

    return axios({
      url: finalUrl,
      method: "GET",
      responseType: "blob",
    });
  },
  getSocialPostsPerformance(orderBy, orderByDirection, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/social/posts?orderBy=${orderBy}&orderByDirection=${orderByDirection}&since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getFacebookPostsPerformance(sort, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/social/posts/facebook?sort=${sort}&posts=10&since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getFollowerGeography() {
    return axios({
      url: `${Globals.API_URL}/api/geography`,
      method: "GET",
    });
  },
  getFollowers(since, until, averages) {
    return axios({
      url: `${Globals.API_URL}/api/person/followers?since=${since}&until=${until}&average=${averages}`,
      method: "GET",
    });
  },
  getInstagramPostsPerformance(sort, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/social/posts/instagram?sort=${sort}&posts=10&since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getLang() {
    return axios({
      url: `${Globals.API_URL}/api/lang/getLanguage`,
      method: "GET",
    });
  },
  getLeagueTable(table, since, until, overrideTenantId) {
    return axios({
      url: `${Globals.API_URL}/api/league/${table}?since=${since}&until=${until}&overrideTenantId=${overrideTenantId}`,
      method: "GET",
    });
  },
  getLeagueClubFollowers(since, until) {
    return axios({
      url: `${Globals.API_URL}/api/league/followers?since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getMetaProfile() {
    return axios({ url: `${Globals.API_URL}/api/person/meta-profile-data`, method: "GET" });
  },
  getMetric(metric, params, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/metric/${metric}?since=${since}&until=${until}`,
      method: "GET",
      params,
    });
  },
  getNextMatch() {
    return axios({ url: `${Globals.API_URL}/api/matches/next`, method: "GET" });
  },
  getPageAccessTokens() {
    return axios({
      url: `${Globals.API_URL}/api/admin/page-access-tokens`,
      method: "GET",
    });
  },
  getPartnerActive() {
    return axios({
      url: `${Globals.API_URL}/api/partners/active`,
      method: "GET",
    });
  },
  getPartnerDividedEngagement(since, until) {
    return axios({
      url: `${Globals.API_URL}/api/partners/engagement/divided?since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getPartnerDividedReach(partnerId = "", selectedTenantId = "", since, until) {
    return axios({
      url: `${Globals.API_URL}/api/partners/reach/divided?partnerId=${partnerId}&selectedTenantId=${selectedTenantId}&since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getPartnerDividedValue(partnerId = "",selectedTenantId = "", since, until) {
    return axios({
      url: `${Globals.API_URL}/api/partners/value/divided?partnerId=${partnerId}&selectedTenantId=${selectedTenantId}&since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getPartnerEngagement(partnerId = "", selectedTenantId = "", since, until) {
    return axios({
      url: `${Globals.API_URL}/api/partners/engagement?partnerId=${partnerId}&selectedTenantId=${selectedTenantId}&since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getPartnerReach(partnerId = "", selectedTenantId = "", since, until) {
    return axios({
      url: `${Globals.API_URL}/api/partners/reach?partnerId=${partnerId}&selectedTenantId=${selectedTenantId}&since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getPartnerValue(since, until) {
    return axios({
      url: `${Globals.API_URL}/api/partners/value?since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getPartnerValuePerPartner(selectedTenantId = "", since, until) {
    return axios({
      url: `${Globals.API_URL}/api/partners/partners?selectedTenantId=${selectedTenantId}&since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getPartnerValuePerSource(partnerId = "", selectedTenantId = "", since, until) {
    return axios({
      url: `${Globals.API_URL}/api/partners/sources?partnerId=${partnerId}&selectedTenantId=${selectedTenantId}&since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getPartnerValuePerStation(partnerId = "", selectedTenantId = "", since, until) {
    return axios({
      url: `${Globals.API_URL}/api/partners/stations?partnerId=${partnerId}&selectedTenantId=${selectedTenantId}&since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getPartners() {
    return axios({
      url: `${Globals.API_URL}/api/partners`,
      method: "GET",
    });
  },

  getPotentialTickets() {
    return axios({
      url: `${Globals.API_URL}/api/matches/potential`,
      });
  },
  getSponsorDividedReach(sponsorObjectId, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/sponsors/reach/divided?since=${since}&until=${until}`,
      method: "GET",
      params: { sponsorObjectId },
    });
  },
  getSponsorDividedEngagement(sponsorObjectId, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/sponsors/engagement/divided?since=${since}&until=${until}`,
      method: "GET",
      params: { sponsorObjectId },
    });
  },
  getSponsorDividedEngagementValue(sponsorObjectId, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/sponsors/engagement/divided/value?since=${since}&until=${until}`,
      method: "GET",
      params: { sponsorObjectId },
    });
  },
  getSocialMediaState() {
    return axios({
      url: `${Globals.API_URL}/api/person/dashboard/social-metrics/status`,
      method: "GET",
    });
  },
  async setSocialMediaState(formData) {
    return axios({
      url: `${Globals.API_URL}/api/person/dashboard/social-metrics/status`,
      method: "POST",
      data: formData,
    });
  },
  async getBoxedMetrics(metric, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/person/dashboard/boxed-metrics?metric=${metric}&since=${since}&until=${until}`,
      method: "GET",
    });
  },
  async getAthleteMetric(metric, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/person/dashboard/athlete-metrics?metric=${metric}&since=${since}&until=${until}`,
      method: "GET",
    });
  },
  async getMediaValuePeriod() {
    return axios({
      url: `${Globals.API_URL}/api/person/media-value-period`,
      method: "GET",
    });
  },
  async getReachValuePeriod() {
    return axios({
      url: `${Globals.API_URL}/api/person/reach-value-period`,
      method: "GET",
    });
  },
  async getEngagementValuePeriod() {
    return axios({
      url: `${Globals.API_URL}/api/person/engagement-value-period`,
      method: "GET",
    });
  },
  async getSponsorsValue() {
    return axios({
      url: `${Globals.API_URL}/api/person/sponsors-value`,
      method: "GET",
    });
  },
  getSoldTickets(since, until) {
    return axios({
      url: `${Globals.API_URL}/api/matches/tickets?since=${since}&until=${until}&reverse=false`,
      method: "GET",
    });
  },
  getSponsorDividedValue(sponsorObjectId, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/sponsors/value/divided?since=${since}&until=${until}`,
      method: "GET",
      params: { sponsorObjectId },
    });
  },
  /* Unsure why this is here. Duplicate name: getSponsorValuePerStation(sponsorObjectId, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/sponsors/reach/divided?since=${since}&until=${until}`,
      method: "GET",
      params: { sponsorObjectId }
          });
  }, */
  getSponsorLogoStats(sponsorObjectId, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/sponsors/logostats?since=${since}&until=${until}`,
      method: "GET",
      params: { sponsorObjectId }
          });
  },
  getSponsorObjects(sponsorObjectId) {
    return axios({
      url: `${Globals.API_URL}/api/sponsors/objects`,
      method: "GET",
      params: { sponsorObjectId }
          });
  },
  getSponsorReach(sponsorObjectId, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/sponsors/reach?since=${since}&until=${until}`,
      method: "GET",
      params: { sponsorObjectId }
          });
  },
  getSponsorValue(sponsorObjectId, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/sponsors/value?since=${since}&until=${until}`,
      method: "GET",
      params: { sponsorObjectId }
          });
  },
  getSponsorValuePerSource(sponsorObjectId) {
    return axios({
      url: `${Globals.API_URL}/api/sponsors/sources`,
      method: "GET",
      params: { sponsorObjectId }
          });
  },
  getSponsorValuePerStation(sponsorObjectId, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/sponsors/stations?since=${since}&until=${until}`,
      method: "GET",
      params: { sponsorObjectId }
          });
  },
  getStadiumSellOut() {
    return axios({
      url: `${Globals.API_URL}/api/stadium-sell-out`,
      method: "GET",
    });
  },
  getTVJobs() {
    return axios({
        url: `${Globals.API_URL}/api/admin/tv-jobs`,
        method: "GET",
    });
  },
  getTenants() {
    return axios({ url: `${Globals.API_URL}/api/tenants`, method: "GET" });
  },
  getTest(name) {
    return axios({
      url: `${Globals.API_URL}/api/test/${name}`,
      method: "GET",
    });
  },
  getTicketsNextMatch() {
    return axios({
      url: `${Globals.API_URL}/api/matches/tickets-next-match`,
      method: "GET",
    });
  },
  getTvEngagement(since, until) {
    return axios({
      url: `${Globals.API_URL}/api/tv/engagement?since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getTvViewers(since, until) {
    return axios({
      url: `${Globals.API_URL}/api/matches/viewers?since=${since}&until=${until}`,
      method: "GET",
    });
  },
  getSponsorTvDivided(since, until, sponsorObjectId) {
    return axios({
      url: `${Globals.API_URL}/api/sponsors/tv-divided?since=${since}&until=${until}`,
      method: "GET",
      params: { sponsorObjectId }
    });
  },
  getUser() {
    return axios({ url: `${Globals.API_URL}/api/user`, method: "GET" });
  },
  getUserCurrency() {
    return axios({
      url: `${Globals.API_URL}/api/currency`,
      method: "GET",
    });
  },
  getVisibility(overrideTenantId, since, until) {
    return axios({
      url: `${Globals.API_URL}/api/visibility?since=${since}&until=${until}&overrideTenantId=${overrideTenantId}`,
      method: "GET",
    });
  },
  getWordcloud(since, until) {
    return axios({
      url: `${Globals.API_URL}/api/brand/wordcloud?since=${since}&until=${until}`,
      method: "GET",
    });
  },
  importMeltwater(formData) {
    return axios({
      url: `${Globals.API_URL}/api/admin/import-meltwater`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      }    
    });
    },
  importTV(formData) {
    return axios({
      url: `${Globals.API_URL}/api/admin/import-tv`,
      method: "POST",
      data: formData,
      headers: {
          "Content-Type": "multipart/form-data",
      }    
    });
  },
  importMedietall(formData) {
    return axios({
      url: `${Globals.API_URL}/api/admin/import-medietall`,
      method: "POST",
      data: formData,
      headers: {
          "Content-Type": "multipart/form-data",
      }
    });
  },
  meltwaterJobsCount() {
    return axios({
      url: `${Globals.API_URL}/api/admin/jobs/meltwater`,
      method: "GET",
    });
  },
  removeUser(userID, name, email) {
    return axios({
      url: `${Globals.API_URL}/api/admin/remove-user?userID=${userID}&name=${name}&email=${email}`,
      method: "POST",
    });
  },
  runFacebookJobs(page, post, insight, stats) {
    return axios({
      url: `${Globals.API_URL}/api/admin/facebook-jobs?page=${page}&post=${post}&insight=${insight}&stats=${stats}`,
      method: "POST",
    });
  },
  runInstagramJobs(post, insight, stats) {
    return axios({
      url: `${Globals.API_URL}/api/admin/instagram-jobs?post=${post}&insight=${insight}&stats=${stats}`,
      method: "POST",
    });
  },
  runOverallMetricsJob() {
    return axios({
      url: `${Globals.API_URL}/api/admin/overall-metrics-job`,
      method: "POST",
    });
  },
  updateTVMetrics() {
    return axios({
        url: `${Globals.API_URL}/api/admin/update-tv-metrics`,
        method: "POST",
    });
  },
  getMergedTenants() {
    return axios({
      url: `${Globals.API_URL}/api/partners/merged-tenants`,
      method: "GET",
    });
  },
  getPerformanceIndex() {
    return axios({
      url: `${Globals.API_URL}/api/person/performance-index`,
    });
  },
  getSocialMediaPost(media, postId) {
    return axios({
      url: `${Globals.API_URL}/api/social/posts/${media}/${postId}`,
      method: "GET",
    });
  },
  getPerformingSocialMediaPosts(since, until, orderBy, orderDirection) {
    return axios({
      url: `${Globals.API_URL}/api/social/posts/performing?since=${since}&until=${until}&orderBy=${orderBy}&orderDirection=${orderDirection}`,
      method: "GET",
    });
  },
  getPerformingSocialMediaMentions(since, until, orderBy, orderDirection) {
    return axios({
      url: `${Globals.API_URL}/api/social/mentions/performing?since=${since}&until=${until}&orderBy=${orderBy}&orderDirection=${orderDirection}`,
      method: "GET",
    });
  },
  getParnterImageDetectionExample(selectedPartnerId) {
    return axios({
      url: `${Globals.API_URL}/api/partners/image-detection-example?partnerId=${selectedPartnerId ? selectedPartnerId : ""}`,
      method: "GET",
    });
  },
  getPersonSocialMetrics() {
    return axios({
      url: `${Globals.API_URL}/api/person/dashboard/social-metrics`,
      method: "GET",
    })
  },
  getTenantProfileData() {
    return axios({
      url: `${Globals.API_URL}/api/person/meta-profile-data`,
      method: "GET",
    })
  },
  getAllBrands() {
    return axios({
      url: `${Globals.API_URL}/api/brand/all`,
      method: 'GET',
    });
  },
  setSponsors(brandIds) {
    return axios({
      url: `${Globals.API_URL}/api/brand/tenant`,
      method: 'POST',
      data: {brandIds},
    });
  }
};
export default ApiService