import React from "react"
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official'
import { Spinner } from 'reactstrap'
import { connect } from "react-redux"
import { useTheme } from "../utils/useTheme";

const BarGraph = ({
  useGraphQuery,
  queryParams = [],
  defaultActiveTab = 1,
}) => {
  const { data, isLoading, isRefetching, isSuccess } = useGraphQuery(
    ...queryParams
  );
  let darkTheme = true
  // check if light or dark mode
  if (useTheme() === "light-mode"){
    darkTheme = false
  }

  const chartOptions = (graph, darkTheme) => {
    var backgroundColor = "#fff"
    var labelcolor = "#000"
    if (darkTheme === true) {
      backgroundColor = "#2B283B"
      labelcolor = "#fff"
    }
    return {
      credits: { enabled: false },
      chart: {
        type: "column",
        backgroundColor: backgroundColor,  // <-- Add background color
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: graph.data.yTitle,
        },
        labels: {
          style: {
            color: labelcolor
         }
        },
      },
      xAxis: {
        categories: graph.data.labels,
        labels: {
          rotation: -75,
          style: {
            color: labelcolor
         }
        },
        title: {
          text: graph.data.xTitle,
        },
        min: 0,
        max: 15,
        scrollbar: {
          enabled: true,
        },
      },
      series: graph.data.series,
      plotOptions: {
        column: {
          borderColor: null,
         },
      },
    };
  };


  return (
    <>
      {(isLoading || isRefetching) && (
        <Spinner
          color="primary"
          size="sm"
          style={{ position: "absolute", right: 20 }}
        />
      )}
      {isSuccess && (
        <>
          {
            data && (
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions(data, darkTheme)}
              />
            )
          }
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  date: state.date,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(BarGraph);
