import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next'
import { FaTiktok, FaFacebook, FaYoutube, FaXTwitter, FaInstagram } from "react-icons/fa6";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Button,
  CardTitle,
  CardText
} from "reactstrap";
import Switch from "react-switch";
import { FaExternalLinkAlt } from "react-icons/fa";
import { format } from "date-fns";
import SocialService from "../services/SocialService";
import { useToggleDataSourcesMutation } from "../query/hooks";

const providerToName = {
  facebook: "Facebook & Instagram",
  'facebook-business': "Facebook (Business)",
  x: "X",
  youtube: 'YouTube',
  tiktok: 'TikTok',
  google: 'Google',
}

const SocialState = ({ social }) => {
  const {accessTokens, provider} = social;
  const socialMediaName = providerToName?.[provider] ?? provider;
  const {mutate: toggleDataSourceMutation} = useToggleDataSourcesMutation();

  const { t } = useTranslation();

  const providerToSocial = () => {
    switch (provider) {
      case 'facebook':
      case 'instagram':
        return SocialService.facebookBusinessLoginLink();
      case 'facebook-business':
        return SocialService.facebookBusinessLoginLink();
      case 'twitter':
        return SocialService.twitterLoginLink();
      default:
        return '#';
    }
  }

  const providerIcons = {
    facebook: FaFacebook,
    twitter: FaXTwitter,
    instagram: FaInstagram, 
  };


  return (
    <Container>
      <Row className="align-items-center">
        <Col xs="6">
          <h5>{ socialMediaName }</h5>
        </Col>
        <Col xs="6" className="text-right">
          <FormGroup className="social-form-group">
            <a href={providerToSocial()}>
              <Button
                className="btn btn-info ml-4 waves-effect waves-light"
                label="Connect"
              >
                {t('Connect')}
              </Button>
            </a>
          </FormGroup>
        </Col>
      </Row>
      

      {accessTokens.map((accessToken) => (
        accessToken.accounts.every(token => token.provider !== "instagram") && (
          <Card className="mb-3">
            <CardBody>
              <CardTitle style={{marginBottom: '20px'}}>{t('Link your Instagram account')}</CardTitle>
              <CardText>
                <p>
                  <Trans i18nKey="linkedInstagramHelp">
                    {t('linkedInstagramHelpIntro')} <a href="https://www.facebook.com/business/help/connect-instagram-to-page" target="_blank" rel="noreferrer" className="text-dark">{t('linkedInstagramHelpLink1')}</a>{t('linkedInstagramHelpBetweenLinks')}<a href="https://help.instagram.com/138925576505882/" className="text-dark" target="_blank" rel="noreferrer">{t('linkedInstagramHelpLink2')}</a>
                  </Trans>.
                </p>
                </CardText>
            </CardBody>
          </Card>
      )))}
      
      {accessTokens.map((accessToken) => (
        <Row className="mb-3">
          <Col>
            <Card>
              <CardBody>
                <CardTitle style={{marginBottom: '20px'}}>{t('Connected accounts')}
                </CardTitle>
                {accessToken.accounts.map((account, index) =>  {
                  const IconComponent = providerIcons[account.provider] || null;
                  console.log(account);
                  return (
                    <div key={index} style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                    
                    <Link style={{color: 'white', minWidth: 0 }} to={{ pathname: account.link}} target="_blank">
                      { IconComponent && <IconComponent />} {account?.name}
                      <FaExternalLinkAlt style={{marginLeft: 10}} />
                    </Link>
                    <Switch
                      onColor="#02a499"
                      onChange={() => toggleDataSourceMutation({
                        provider: accessToken.provider,
                        accountProvider: account.provider,
                        accessTokenId: accessToken.id,
                        accountId: account.id,
                        newState: account.include ? 0 : 1
                      })}
                      checked={!!account?.include}
                    />
                  </div>
                  );
                })}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default SocialState;
