import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import AppRoute from './routes/AppRoute'
import history from './utils/history'
import { authProtectedRoutes, publicRoutes } from "./routes/";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import createAuth0Client from '@auth0/auth0-spa-js';
import ApiService from './services/ApiService';
import { setCurrencies } from './store/actions/currencyActions';
import fetchTenants from './store/actions/fetchTenants'

import posthog from 'posthog-js'
import PostHogPageviewTracker from './PostHogPageviewTracker';

import {
  setUserTenant,
  setIsAuthenticated,
  getUser,
  setLoading,
  setAccessToken,
  createAuthClient,
  loginUser,
  logoutUser
} from './store/actions/authActions';

import 'react-chat-widget/lib/styles.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      staleTime: 1000 * 60, // 1 Minute
      cacheTime: 1000 * 60 * 60 * 24 * 7 * 3, // 3 Weeks
      refetchOnWindowFocus: false,
    },
  },
});

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

const App = (props) => {
  const {
    domain = '',
    client_id = '',
    audience = '',
    onRedirectCallback = () => {},
    setLoading = () => {},
    createAuthClient = () => {},
    setAccessToken = () => {},
    loginUser = () => {},
    fetchTenants = () => {},
    setCurrencies = () => {},
    getUser = () => {},
    setIsAuthenticated = () => {},
    tenants = [],
    tenant = null,
    setUserTenant = () => {},
    logoutUser= () => {},
  } = props;

  useEffect(() => {
    const initAuth0 = async () => {
      setLoading(true);
      const auth0Client = await createAuth0Client({
        domain,
        client_id,
        audience,
        redirect_uri: window.location.origin + window.location.search,
      });

      createAuthClient(auth0Client);

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0Client.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0Client.isAuthenticated();

      if (isAuthenticated) {
        const user = await auth0Client.getUser();
        const access_token = await auth0Client.getTokenSilently();
        setAccessToken(access_token);

        // The user is logged in, let's fetch the tenants they have access to
        loginUser(user);
        
        await fetchTenants()

        // Pass the access token to the ApiService.getCurrencyConvertion function
        const resConvertion = await ApiService.getCurrencyConvertion(access_token);
        setCurrencies(resConvertion.data.conversion_rates);

        getUser();
      }
      setIsAuthenticated(isAuthenticated);
      setLoading(false);
    };
    initAuth0();
  }, [
    domain,
    client_id,
    audience,
    onRedirectCallback,
    setLoading,
    createAuthClient,
    setAccessToken,
    loginUser,
    fetchTenants,
    setCurrencies,
    getUser,
    setIsAuthenticated,
    setUserTenant,
  ]);

  useEffect(() => {
    
    if (tenants.length > 0) {

      const selectedTenantId = localStorage.getItem('selectedTenantId');

      if (tenant !== undefined && tenant !== null) {
        // tenant is already set
        return ;
      } else {
        let tenant;
        if (selectedTenantId) {
          const selectedTenant = tenants.find(( {id} ) => id === parseInt(selectedTenantId));
          if (selectedTenant) {
            setUserTenant(selectedTenant);
            tenant = selectedTenant;
          } else {
            setUserTenant(tenants[0]);
            tenant = tenants[0];
          }
        } else {
          tenant = tenants[0];
          setUserTenant(tenants[0])
        }
      }
    }
  }, [tenants, tenant, setUserTenant]);

  // Use useSelector to access user data from the Redux store
  const user = useSelector(state => state.auth.user);

  // Use useEffect to log the user data when it changes
  useEffect(() => {
    //  Identify for PostHog
    if (user) {
      if (user.id !== undefined) {
        posthog.identify(user.id, {
          email: user.email,
          name: user.name
        })
      }
    }
  }, [user]);

  return (
    <>
      <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: localStoragePersister }}>
      <ReactQueryDevtools initialIsOpen={false} />
        <Router history={history}>
          <PostHogPageviewTracker />
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={route.layout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}
            {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={route.layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                />
              ))}
          </Switch>
        </Router>
      </PersistQueryClientProvider>
      
      </>
  );
};

App.propTypes = {
  createAuthClient: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  setUserTenant: PropTypes.func.isRequired,
  fetchTenants: PropTypes.func.isRequired,
  setIsAuthenticated: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setAccessToken: PropTypes.func.isRequired,
  setCurrencies: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
  client_id: PropTypes.string.isRequired,
  audience: PropTypes.string.isRequired,
  onRedirectCallback: PropTypes.func.isRequired,
  tenants: PropTypes.array.isRequired,
  tenant: PropTypes.object,
};

const mapStateToProps = state => ({
  tenants: state.auth.tenants,
  tenant: state.auth.tenant,
});

export default connect(
  mapStateToProps,
  {
    setUserTenant,
    fetchTenants,
    setIsAuthenticated,
    getUser,
    setLoading,
    setAccessToken,
    createAuthClient,
    loginUser,
    logoutUser,
    setCurrencies,
  }
)(App);