import React from "react";
import { connect } from "react-redux";

import BoxedMetric from "../components/BoxedMetric";
import PerformanceIndex from "../components/PerformanceIndex";
import PerformingSocialMediaPosts from "../components/PerformingSocialMediaPosts";
import PerformingSocialMediaMentions from "../components/PerformingSocialMediaMentions";
import numeral from "numeral";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

const PerformancePerson = () => {
  const { t } = useTranslation();
  const primarySocialFn = (primary) => {
    return `${numeral(primary).format('0.0a')} ${t('Followers')}`;
  }

  const secondarySocialFn = (secondary) => {
    return `${t('Engagement Rate')} ${numeral(secondary).format('0%')}`;
  }

  return (
    <div className="container-fluid pt-4 p-0 value-person-view">
      <h5>{t('Social Metrics Overview')}</h5>
      <Row className="mb-4">
        <Col xs={6}>
          <BoxedMetric
            icon="instagram"
            title="Instagram"
            metric="instagram-followers"
            subtitle={t("Followers")}
            colour="red"
            text={t("Engagement Rate:")}
            link="/performance/source?source=instagram"
            primaryFn={primarySocialFn}
            secondaryFn={secondarySocialFn}
          />
          </Col>
          <Col xs={6}>
          <BoxedMetric
            icon="x"
            title="X"
            metric="x-followers"
            subtitle={t("Followers")}
            colour="yellow"
            text={t("Engagement Rate:")}
            link="/performance/source?source=x"
            primaryFn={primarySocialFn}
            secondaryFn={secondarySocialFn}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={6}>
          <BoxedMetric
            icon="facebook"
            title="Facebook"
            metric="facebook-followers"
            subtitle={t("Followers")}
            colour="greyblue"
            text={t("Engagement Rate:")}
            link="/performance/source?source=facebook"
            primaryFn={primarySocialFn}
            secondaryFn={secondarySocialFn}
          />
        </Col>
        <Col xs={6}>
          <BoxedMetric
            icon="youtube"
            title="Youtube"
            metric="youtube-followers"
            subtitle={t("Followers")}
            colour="blue"
            text={t("Engagement Rate:")}
            link="/performance/source?source=youtube"
            primaryFn={primarySocialFn}
            secondaryFn={secondarySocialFn}
          />
        </Col>
      </Row>
      <div className="mb-4">
        <PerformanceIndex />
      </div>

      <div className="mb-4">
        <PerformingSocialMediaPosts />
      </div>

      <div className="mb-4">
        <PerformingSocialMediaMentions />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  selectedCurrency: state.currency.selectedCurrency,
	tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(PerformancePerson);
