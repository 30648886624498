import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { ATHLETE, ENGAGEMENT_VALUE_PERIOD } from "../keys";

const useEngagementValuePeriod = () => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [ATHLETE, ENGAGEMENT_VALUE_PERIOD, tenant.id],
    queryFn: () => ApiService.getEngagementValuePeriod(),
    select: (data) => JSON.parse(JSON.stringify(data.data.graphs)),
    placeholderData: { data: { graphs: [] } },
  });
};
export default useEngagementValuePeriod;
