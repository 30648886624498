import React, { useMemo, useState } from "react"
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Spinner, UncontrolledDropdown } from 'reactstrap'
import classnames from 'classnames'
import { connect } from "react-redux"
import { useTheme } from "../utils/useTheme";
import { ChevronDownIcon } from "@radix-ui/themes/dist/cjs/index.js";
import { useTranslation } from "react-i18next";

const LineGraph = ({
  useGraphQuery,
  queryParams = [],
  defaultActiveTab = 1,
  multipliers = [],
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const { data, isLoading, isRefetching, isSuccess } = useGraphQuery(
    ...queryParams
  );
  const [multiplier, setMultiplier] = useState(1);
  var darkTheme = true
  // check if light or dark mode
  if (useTheme() === "light-mode"){
    darkTheme = false
  }
  const activeTabData = useMemo(() => JSON.parse(JSON.stringify(data?.[activeTab] ?? [])), [data, activeTab]);

  const chartOptions = (graph, darkTheme) => {
    var backgroundColor = "#fff"
    var labelcolor = "#000"
    if (darkTheme === true) {
      backgroundColor = "#2B283B"
      labelcolor = "#fff"
    }
    const series = graph.data.series.map((s) => ({
      ...s,
      data: s.data.map((d) => d * multiplier),
    }));
    return {
      credits: { enabled: false },
      chart: {
        type: "spline",
        backgroundColor: backgroundColor,  // <-- Add background color
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: graph.data.yTitle,
          style: {
            color: labelcolor
          }
        },
        labels: {
          style: {
            color: labelcolor
         }
        }
      },
      xAxis: {
        categories: graph.data.labels,
        labels: {
          //rotation: 0,
          step: 0,
          style: {
            color: labelcolor
         }
        },
        title: {
          text: graph.data.xTitle,
          style: {
            color: labelcolor
          }
        },
      },
      series,
    };
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const renderTab = (graph, index) => {
    return (
      <NavItem key={index}>
        <NavLink
          href="#"
          className={classnames({ active: activeTab === index })}
          onClick={() => toggleTab(index)}
        >
          {graph.source}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <>
      {(isLoading || isRefetching) && (
        <Spinner
          color="primary"
          size="sm"
          style={{ position: "absolute", right: 20 }}
        />
      )}
      {isSuccess && (
        <>
          <div style={{display: 'flex', alignItems: 'center'}} className="my-4 flex wrap flex-wra">
            <div style={{flex: 1}}>
              {multipliers.length > 0 && (
                <div className="md-flex" style={{alignItems: 'center', flexDirection: 'column'}}>
                  <span>{t('CPM discount')}</span>
                  <UncontrolledDropdown style={{flex: 1, padding: 0}}>
                    <DropdownToggle className="btn " tag="button" style={{padding: 0}}>
                      <Button size="sm" color="light" outline>
                        {multiplier * 100}%
                        <ChevronDownIcon style={{marginLeft: '8px'}} />
                      </Button>
                    </DropdownToggle>
                    <DropdownMenu>
                      {multipliers.map(multiplier => (
                        <DropdownItem key={`multiplier-${multiplier}`} onClick={() => setMultiplier(multiplier)}>{multiplier * 100}%</DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )}
            </div>
            <Nav pills className="navtab-bg center-pills">
              {Array.isArray(data) && data.map((graph, index) => renderTab(graph, index))}
            </Nav>
            <div style={{flex: 1}}></div>
            </div>
            {
              activeTabData && data?.[activeTab] && (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions(activeTabData, darkTheme)}
                />
              )
            }
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  date: state.date,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(LineGraph);
