import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { ATHLETE, REACH_VALUE_PERIOD } from "../keys";

const useReachValuePeriod = () => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [ATHLETE, REACH_VALUE_PERIOD, tenant.id],
    queryFn: () => ApiService.getReachValuePeriod(),
    select: (data) => JSON.parse(JSON.stringify(data.data.graphs)),
    placeholderData: { data: { graphs: [] } },
  });
};
export default useReachValuePeriod;
