import { SET_USER_TENANT, SET_USER, SET_IS_AUTHENTICATED, SET_LOADING, SET_ACCESS_TOKEN, CREATE_AUTH_CLIENT, LOGIN_USER, LOGOUT_USER, FETCH_USER_TENANTS_ERROR, FETCH_USER_TENANTS_SUCCESS, FETCH_USER_TENANTS_PENDING, SET_TEST } from '../actions/types';

const initialState = {
    client: null,
    user: null,
    tenant: null,
    access_token: null,
    loading: true,
    is_authenticated: false,
    tenants: [],
    tenants_pending: false,
    tenants_error: null
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_IS_AUTHENTICATED:
            return {
                ...state,
                is_authenticated: action.payload
            }
        case SET_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                },
            }
        case SET_USER_TENANT:
            return {
                ...state,
                tenant: action.payload
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                access_token: action.payload
            }
        case CREATE_AUTH_CLIENT:
            return {
                ...state,
                client: action.payload
            }
        case LOGIN_USER:
            return {
                ...state,
                user: action.payload
            }
        case SET_TEST:
            return {
                ...state,
                test: action.payload
            }
        case LOGOUT_USER:
            return {
                ...state,
                user: null
            }
        case FETCH_USER_TENANTS_PENDING:
            return {
                ...state,
                tenants_pending: true
            }
        case FETCH_USER_TENANTS_SUCCESS:
            return {
                ...state,
                tenants_pending: false,
                tenants: action.payload
            }
        case FETCH_USER_TENANTS_ERROR:
            return {
                ...state,
                tenants_pending: false,
                tenants_error: action.payload
            }
        default:
            return state
    }
}

export default authReducer
