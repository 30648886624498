import React, { useEffect, useState } from 'react'
import TopBar from './TopBar'
import NavbarEliteserien from './NavbarEliteserien'
import NavbarToppserien from './NavbarToppserien'
import NavbarLeague from './NavbarLeague'
import NavbarSponsor from './NavbarSponsor'
import NavbarFoqus from './NavbarFoqus'
import Footer from './Footer'
import { connect } from 'react-redux'
import AthleteLayout from './AthleteLayout'

const DefaultLayout = ({toggleRightSidebar, topbarTheme, tenant, children, date}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  if (tenant.template === 'person') {
    return <AthleteLayout children={children} />
  }

  return (
    <div id="layout-wrapper">
      {tenant.template !== 'person' && (
        <TopBar
          theme={topbarTheme}
          isMenuOpened={isMenuOpened}
          toggleRightSidebar={toggleRightSidebar}
          openLeftMenuCallBack={openMenu}
          tenant={tenant}
        />
      )}
      {
        {
          'eliteserien': <NavbarEliteserien menuOpen={isMenuOpened} />,
          'obosligaen': <NavbarEliteserien menuOpen={isMenuOpened} />,
          'league': <NavbarLeague menuOpen={isMenuOpened} date={date} tenant={tenant}/>,
          'toppserien': <NavbarToppserien menuOpen={isMenuOpened} />,
          'sponsor': <NavbarSponsor menuOpen={isMenuOpened} />,
          'foqus': <NavbarFoqus menuOpen={isMenuOpened} />
        }[tenant.template]
      }
      <div className="main-content">
        <div className="page-content">{children}</div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = state => ({
  tenant: state.auth.tenant,
  date: state.date
})

export default connect(mapStateToProps, {})(DefaultLayout)